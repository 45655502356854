

const Auth = {
  login : async (user, senha) => {
    try{
      const url = `${process.env.REACT_APP_URL_API}/public/autenticar`;
      const buffer = {
        user,
        senha,
        app_key           : process.env.REACT_APP_KEY,
        loginPorAPI       : false,
      }
      //console.log("Buffer: ", buffer);
      const req = await fetch(url, {
        method    : "POST",
        headers   : {
          "Content-Type"  : "application/json",
          "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`,
        }, 
        body      : JSON.stringify(buffer),
      });
      const res = await req.json();
      console.log("Resposta: ", res);
      return {status: true, req: res}
    } catch (error) {
      //console.log("Erro: ", error);
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", error}
    }
  },
  logout    : async (tkn) => { // ROTA PRIVADA
    try{
      const url = `${process.env.REACT_APP_URL_API}/auth/logout/`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : {
          "aplication"    : "application/json",
          "Content-Type"  : "application/json",
          "Authorization" : `Bearer ${tkn}`,
        },
        body      : JSON.stringify({token: tkn})
      });
      return await req.json();
    } catch (error) {
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", error}
    }
  },
  cadastrar : async (user) => { // ROTA PÚBLICA
    try{
      const url = `${process.env.REACT_APP_URL_API}/public/criar_usuario/?cadastroPorAPI=${false}`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : {
          "aplication"    : "application/json",
          "Content-Type"  : "application/json",
          "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`
        },
        body      : JSON.stringify(user)
      });
      const res = await req.json();
      return res;
    } catch (e) {
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", req:e}
    }
  },
  // requisição para verificar se o usuário está logado
  isLogged  : async (id, tkn) => { // ROTA PRIVADA
    try{
      const url = `${process.env.REACT_APP_URL_API}/auth/validarUsuario/`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : {
          "aplication"    : "application/json",
          "Content-Type"  : "application/json",
          "Authorization" : `Bearer ${tkn}`,
        },
        body      : JSON.stringify({
          idusuario       : id,
        })
      });
      const res = await req.json();
      return {status: true, req: res}
    } catch (e) {
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", req: e}
    }
    // requizição para Verificar se o usuário está logado
  },
  // requisição para recuperar a senha
  recuperarSenha : async (email) => { // ROTA PÚBLICA
    const url = `${process.env.REACT_APP_URL_API}/public/esqueceu_senha/?email=${email}&app_key=${null}`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`
      },
    });
    return await req.json();
  },
  // requisição para validar o token de recuperação de senha informado no email
  validarToken    : async (email, tkn) => {
    const url = `${process.env.REACT_APP_URL_API}/public/verificarTokenRecuperarSenha/`;
    const req = await fetch(url, {
      method    : "POST",
      headers   : {
        "aplication"    : "application/json",
        "Content-Type"  : "application/json",
        "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`
      },
      body      : JSON.stringify({email, token : tkn })
    });
    return await req.json();
  },
  // requisição para alterar a senha
  alterarSenha : async (type, email, senha, tkn) => { // ROTA PÚBLICA
    if( type ){
      const url = `${process.env.REACT_APP_URL_API}/public/resetar_senha`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : {
          "aplication"    : "application/json",
          "Content-Type"  : "application/json",
          "Authorization" : `Bearer ${process.env.REACT_APP_TOKEN}`
        },
        body      : JSON.stringify({email, senha, token:tkn})
      });
    return await req.json();
    } else {
      /* TODO : Implementar consumo da rota com usuário já logado  */
      //console.log("TODO : Implementar consumo da rota com usuário já logado");
    }
  },
}; // Fim Auth
export default Auth;