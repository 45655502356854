import React                from "react";
// Componentes da página de login
import { ContainMax, ContainMid }       from "../../components/Primar";
import { FormLogin }        from "../../components/Forms";
import { AuthContext }      from "../../contexts/AuthContext";
import RouterHook           from "../../contexts/RouterHook";


import "./stylesL.css"

export default function Login() {
  
  const serverRouter              = RouterHook();
  const { 
    date, isLogged, loading, setLoading, functions 
  } = React.useContext(AuthContext);

  async function VerificarLogin(){
    /*//console.log("Verificando login...");
    const res = await isLogged();
    //console.log("Resposta da verificação de login: ", res);
    if(res.status){
      if(date.user.estado === null || date.user.cidade === null){
        setModalL(true);
        setLoading(false); // Finaliza o loading, por trás dos panos!
      } else {
        const label = Services.ReturnLabelURL(date.user.estado, date.user.cidade);
        //console.log("Label: ", label);
        //console.log("Login realizado com sucesso!");
        setLoading(false); // Finaliza o loading, por trás dos panos!
        serverRouter("home", label);
      }
    } else {
      //LocalStorage.clear();
      setLoading(false);
    }
    */
  }

  React.useEffect(() => {
    document.title = "Acessar Conta | Gestor Agendei";
    VerificarLogin();
  }, []);

  return( 
    <ContainMax>
      { loading ? 
        <></>
          :
        <>
          <ContainMid>
            <div className = "contain-centraliza-login">
              <FormLogin/>
            </div>
          </ContainMid>
        </>
      }
    </ContainMax>
  );
}
