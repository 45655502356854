import { useNavigate } from 'react-router-dom';

export default function RouterHook(){
  const navigate = useNavigate();

  const serverRouter = (rota, query, data) => {
    switch(rota){
      case "back"             :   return navigate(-1);

      case "login"            :   return navigate(`/`);
    }
  }

  return serverRouter;
}