import React                from "react";
import { CompInputForm }    from "../../Basics";
import { BiLeftArrowAlt }   from "react-icons/bi";
import { TbNumbers }        from "react-icons/tb";
import { MdEmail, MdLock }  from "react-icons/md";
import { AuthContext }      from "../../../contexts/AuthContext";
import { 
  login1, logo, recPass, confPassLogo
} from "../../../assets";
import Alerts               from "../../Basics/AlertsSwall";
import validator            from "validator";
import { 
  LocalStorage, Services
} from "../../../services";
import "./stylesFL.css";
import md5 from "md5";

export default function FormLogin() {
  // Contexto de autenticação

  const { loading, login }          =   React.useContext(AuthContext);
  
  const [ stt, setStt ]                 =   React.useState({
    //email                 : "",
    //password              : "",
    email                 : "williandomingues.aluno@unipampa.edu.br",
    password              : "qweqwe",
    cod                   : "",
    newPass               : "",
    confPass              : "",
    codOk                 : false,
    forca                 : "",
    states                : 0,
  });
  
  
  //Escuta a tecla enter para submeter o formulário
  React.useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if(stt.states == 0)       StartLogin(stt.email, stt.password);
        else if(stt.states == 1)  StartRedefinirSenha(stt.email);
        else if(stt.states == 2)  StartValidToken(stt.email, stt.cod);
        else if(stt.states == 3)  StartRedefinirPass(stt.email, stt.newPass, stt.confPass);
      }
    }
    if(stt.states === 0) document.title = "Acessar Conta | Gestor Agendei";
    else             document.title = "Atualizar Senha | Agendei Quadras";
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [ stt ]);

  const hasOneHourPassed = (dtAtual, dtExp) => {
    const differenceInMilliseconds = dtAtual - dtExp;
    const differenceInHours = differenceInMilliseconds / 1000 / 60 / 60;
    //console.log("Diferença em horas: ", differenceInHours);

    return differenceInHours >= 1;
  }
  //const honue = LocalStorage.get("url-empresa");
  //console.log("Honue: ", honue);
  //LocalStorage.clear();
  async function StartLogin(email, pass) {
    // Preciso validar os inputs
    console.log("Email: ", email, "Senha: ", pass);
    if(validator.isEmail(email) && pass.length > 0){ // Verifica se o email é valido e se a senha não é vazia
      //setLoading(true); // Inicia o loading
      Alerts.sucesso(
        "Aguarde",
        "Estamos verificando suas credenciais"
      );
      //console.log("Senha fornecida!!!", pass);
      console.log("Email: ", email, "Senha: ", md5(pass));
      const resp = await login(email, pass);
      console.log("Resposta Form: ", resp);
      if(resp.status){
        //console.log("Aqui! cidade: ", resp[1].user.cidade);

      }else{
        //console.log("Login não realizado!");
        /*Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: 'E-mail ou senha incorretos',
          confirmButtonText : 'Entendi',
          confirmButtonColor: '#DA5A34',
          timer: 6000
        });*/
        //setLoading(false); // Finaliza o loading, por trás dos panos!
      }
    }else{
      // Verifica por que o email não é valido
      //if(email.length == 0) // Email Vazio
        /*Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: 'Insira seu e-mail e senha',
          confirmButtonText : 'Entendi',
          confirmButtonColor: '#DA5A34',
          timer: 6000
        });*/
      //else // Email inválido
        /*Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'E-mail inválido',
          confirmButtonText   : 'Entendi',
          confirmButtonColor  : '#DA5A34',
          timer               : 6000
        });*/
      //console.log("Email inválido!");
    }
    //setLoading(false); // Finaliza o loading, por trás dos panos!
  }
  // Inicia o processo de recuperação de senha, enviando o email
  async function StartRedefinirSenha(email) {
    
  }
  // Inicia o processo de validação do token de recuperação de senha
  async function StartValidToken(email, cod) {}
  // Inicia o processo de redefinição de senha
  async function StartRedefinirPass(email, newPass, confPass) {}
  
  // Define o titulo do botão de acordo com o estado na redefinição de senha
  function ReturnLabelBtt(){
    if(stt.states === 1){
      return "Enviar código";
    } else if(stt.states === 2){
      return "Validar código";
    } else if(stt.states === 3){
      return "Redefinir senha";
    } else if(stt.states === 4){
      return "Acessar minha conta";
    }
  }
  // Renderiza o formulário de acordo com o estado
  function RenderFormRecSenha(){
    if(stt.states === 1){ // Pega o email, e solicita que o código de autenticidade seja enviado para o email
      return (
        <>
          <img className = "img-rec-pass-step-2" src = {recPass} alt = "Logo Agendei Quadras" />
          <h3 className = "text-label-center-h3">
            Enviaremos um código único que deve ser digitado na próxima etapa.
          </h3>
          <CompInputForm
            placeHolder = {"E-mail"}
            type        = {"email"}
            value       = {stt.email}
            onChange    = {(e) => setStt({...stt, email: e.target.value})}
            Icon        = {MdEmail}
            lock        = {false}
          />
        </>
      );
    } else if(stt.states === 2) { // Pega o código enviado por email, e valida se é realmente o código.
      return(
        <>
          <h3 className = "text-label-center-h3">
            Um e-mail foi enviado para você, insira o código informado para prosseguir!
          </h3>
          <CompInputForm
            placeHolder = {"Código"}
            type        = {"text"}
            value       = {stt.cod}
            onChange    = {(e) => setStt({...stt, cod: e.target.value})}
            Icon        = {TbNumbers}
            lock        = {false}
          />
          
        </>
      );
    } else if(stt.states === 3){ //  Pega a nova senha!
      return(
        <>
          <h3 className = "text-label-center-h3">
            Informe os campos abaixo para redefinir sua senha!
          </h3>
          <CompInputForm
            placeHolder = {"Nova Senha"}
            typeA       = {"password"}
            value       = {stt.newPass}
            onChange    = {(e) => {
              setStt({...stt, newPass: e.target.value, forca: Services.validarSenha(e.target.value)});
            }}
            Icon        = {MdLock}
            lock        = {true}
          />
          <CompInputForm
            placeHolder = {"Confirmar Senha"}
            typeA        = {"password"}
            value       = {stt.confPass}
            onChange    = {(e) => setStt({...stt, confPass: e.target.value})}
            Icon        = {MdLock}
            lock        = {true}
          />
          
          {
            /* TODO ::> Implementar força de senha !!  */
            stt.forca &&
            <h4>{stt.forca}</h4>
          }
        </>
      );
    } else if(stt.states === 4){ //  Aviso de senha alterada!
      return (
        <>
          <img className = "img-rec-pass-step-2" src = {confPassLogo} alt = "Confirmação de senha alterada" />
          <h3 className = "text-label-center-h3">
            Agora acesse sua conta com a nova senha!
          </h3>
        </>
      );
    } 
  }
  //console.log("Developer", developer)
  if(stt.states == 0){ // Realiza o login
    return (
      <>
        {!loading ?
          <div className = "form-login">
            <div className = "form-login-img">
              <img src= {login1}/>
            </div>
            <div className = "contain-form-login">
              {/* Container com fomr para login */}
              <div className = "contain-inputs">
                <img className = "logo-img-login" src = {logo} alt = "Logo Agendei Quadras" />
                <CompInputForm
                  placeHolder   = {"E-mail"}
                  type          = {"email"}
                  value         = {stt.email}
                  onChange      = {(e) => setStt({...stt, email: e.target.value})}
                  Icon          = {MdEmail}
                  lock          = {false}
                  autoComplete  = {"email"}
                />
                <CompInputForm
                  placeHolder   = {"Senha"}
                  typeA         = {"password"}
                  value         = {stt.password}
                  onChange      = {(e) => setStt({...stt, password: e.target.value})}
                  Icon          = {MdLock}
                  lock          = {true}
                  autoComplete  = {"current-password"}
                />
                <button className = "button-login"
                  onClick = {() => {
                    StartLogin(stt.email, stt.password);
                  }}
                  title   = "Clique para entrar"
                >
                  Acessar minha conta
                </button>
                <a
                  onClick = {() => {setStt({...stt, states: 1})}}
                  title   = "Clique para recuperar sua senha"
                >Esqueci minha senha</a>
                <div className = "contain-bar-info-line">
                  <div/>
                  <label className = "text-label-low">
                    Ainda não tem uma conta?
                  </label>
                  <div/>
                </div>
                <button 
                  className = "button-login"
                  onClick   = {() => {
                    
                  }}
                  title     = "Clique para criar uma conta"
                >
                  Tenho interesse em utilizar
                </button>
              </div>
            </div>
          </div>
            :
          <>Componente Loading</>
      }
      </>
    );
  } else { // Realiza a recuperação de senha
    return(
      <>
        { !loading ?
          <div className = "form-login-rec-pass">
            
            <div className = "contain-form-login-rec-pass">
              <BiLeftArrowAlt
                size      = {35}
                title     = "Clique para voltar"
                className = "icon-button-back"
                onClick   = {() => {
                  setStt({...stt, states: stt.states - 1});
                }}
              />
              <div className = "contain-inputs">
                <h1 className = "title-components-h1"> 
                  {stt.states !== 4 ? "Redefinição de Senha" : "Senha Redefinida"}
                </h1>
                {RenderFormRecSenha()}
                <button 
                  className = "button-login"
                  title     = "Clique para prosseguir"
                  onClick = {() => {
                    if(false){ //*Modo Prodcur
                      //if(states == 1)               StartRedefinirSenha(email);
                      //else if(states == 2)          StartValidToken(email, cod);
                      //else if(codOk && states == 3) StartRedefinirPass(email, newPass, confPass);
                      //else if(states == 4)          setStates(0);
                    } else { //*Modo Teste
                      //* Modo Teste
                      if(stt.states == 0)      setStt({...stt, states: 1 });
                      if(stt.states == 1)      setStt({...stt, states: 2 });
                      else if(stt.states == 2) setStt({...stt, states: 3 });
                      else if(stt.states == 3) setStt({...stt, states: 4 });
                      else if(stt.states == 4) setStt({...stt, states: 0 });
                      //*/
                    }
                    
                  }}
                  >
                  {ReturnLabelBtt()}
                </button>  
              </div>
            </div>    
          </div>
            :
          <div className = "contain-loading">
            
          </div>
        }      
      </>
    );
  }
}