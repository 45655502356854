import React                from "react";
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import AuthContextProvider  from "./contexts/AuthContext";
import {
  Login
} from "./screens/";

export default function AppRouter() {

  const labels = {
    login         : "/",
  }
  function Rotas(){
    return (
      <Routes>
        <Route path = {labels.login}         element={<Login/>}        />
      </Routes>
    );
  }

  return (
    <Router>
      <AuthContextProvider>
        { Rotas() }
      </AuthContextProvider>
    </Router>
  );
}