import zxcvbn from 'zxcvbn';
import LocalStorage from './LocalStorage';

const Services = {
  DataFormat                : (format, date, pedido) => {
    const data = new Date(date);
    switch (format) {
      case "DD/MM/YYYY"   : 
        const dia = data.getDate() < 10 ? "0"+data.getDate() : data.getDate();
        const mes = data.getMonth()+1 < 10 ? "0"+(data.getMonth()+1) : (data.getMonth()+1);
        return `${dia}/${mes}/${data.getFullYear()}`;
      
      case "dia,DD/MM/YY" : return `${Services.ReturnDiaSemana(3, data)}, ${data.getDate() < 10 ? "0"+data.getDate() : data.getDate()}/${data.getMonth()+1 < 10 ? "0"+(data.getMonth()+1) : (data.getMonth()+1)}/${data.getFullYear().toString().substr(2,2)}`;
      // Preciso retornar a seguinte string: "Todas(os) as(os) {dia da semana} das HH:MM às HH:MM"
      case "label-hf"     : return `${ReturnLabelHF(data.getDay())} ${Services.ReturnDiaSemana(2, data)} das ${Services.ReturnHorarioString(pedido)} `;
      // Preciso retornar a seguinte string: "A partir de {dia da semana}, {número do mês} de {mês} de {ano} (Durante {parâmetro} mês)"
      case "label-hf-2"   : return `A partir de ${Services.ReturnDiaSemana(1, data)}, ${data.getDate()} de ${ReturnMes(data.getMonth()+1)} de ${data.getFullYear()}`;
    }
    
    function ReturnLabelHF(dia){
      if(dia === 0 || dia === 6)  return "Todos os";
      else                        return "Todas as";
    }
    function ReturnMes(mes){
      //verificar o número do mês e retornar o nome
      switch (mes) {
        case 1  : return "Janeiro";
        case 2  : return "Fevereiro";
        case 3  : return "Março";
        case 4  : return "Abril";
        case 5  : return "Maio";
        case 6  : return "Junho";
        case 7  : return "Julho";
        case 8  : return "Agosto";
        case 9  : return "Setembro";
        case 10 : return "Outubro";
        case 11 : return "Novembro";
        case 12 : return "Dezembro";
        default : return "Erro";
      }
    }
  },
  ArrumaEndereco : (str) =>{
    const lowerCaseWords = ['de', 'do', 'da', 'dos', 'das']; // Lista das palavras que devem ficar em minúscula
    return str.toLowerCase().split(' ').map(function(word, index) {
        if (index !== 0 && lowerCaseWords.includes(word)) {
            return word;
        }
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  },
  ReturnDiaSemana(mode, data) {
    // Array de dias da semana
    const dia1 = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    const dia2 = ["Domingos", "Segundas", "Terças", "Quartas", "Quintas", "Sextas", "Sábados"];
    const dia3 = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const dia4 = ["Domingos", "Segundas-feiras", "Terças-feiras", "Quartas-feiras", "Quintas-feiras", "Sextas-feiras", "Sábados"];
    //console.log("data = ", data);
    switch (mode) {
      case 1 : return dia1[data.getDay()];
      case 2 : return dia2[data.getDay()];
      case 3 : return dia3[data.getDay()];
      case 4 : return dia4[data.getDay()];
    }
  },
  RetornaDiaSemana          : (data) => {
    const diaSemana   = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const dia         = new Date(data);
    //console.log("dia = ", data);
    //console.log("diaSemana[dia.getDay()] = ", diaSemana[dia.getDay()]);
    return diaSemana[dia.getDay()];
  },
  ConverteData              : (mode, data) => {
    // Converte dd/mm/yyyy para yyyy-mm-dd -> mode = true
    if(mode){
      const dataSplit = data.split("/");
      return ""+dataSplit[2]+"-"+dataSplit[1]+"-"+dataSplit[0];
    } else { 
      // Converte yyyy-mm-dd para dd/mm/yyyy -> mode = false
      const dataSplit = data.split("-");
      return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
    }
  },
  RetornaEstados            : (estado) => {
    switch (estado) {
      // Verificar estado e retornar a sigla
      case "Acre"               : return "AC";
      case "Alagoas"            : return "AL";
      case "Amapá"              : return "AP";
      case "Amazonas"           : return "AM";
      case "Bahia"              : return "BA";
      case "Ceará"              : return "CE";
      case "Distrito Federal"   : return "DF";
      case "Espírito Santo"     : return "ES";
      case "Goiás"              : return "GO";
      case "Maranhão"           : return "MA";
      case "Mato Grosso"        : return "MT";
      case "Mato Grosso do Sul" : return "MS";
      case "Minas Gerais"       : return "MG";
      case "Pará"               : return "PA";
      case "Paraíba"            : return "PB";
      case "Paraná"             : return "PR";
      case "Pernambuco"         : return "PE";
      case "Piauí"              : return "PI";
      case "Rio de Janeiro"     : return "RJ";
      case "Rio Grande do Norte": return "RN";
      case "Rio Grande do Sul"  : return "RS";
      case "Rondônia"           : return "RO";
      case "Roraima"            : return "RR";
      case "Santa Catarina"     : return "SC";
      case "São Paulo"          : return "SP";
      case "Sergipe"            : return "SE";
      case "Tocantins"          : return "TO";
      default                   : return "";
    }
  },
  RetornaEstadoSigla        : (sigla) => {
    switch (sigla.toLowerCase()) {
      case "ac": return "Acre";
      case "al": return "Alagoas";
      case "ap": return "Amapá";
      case "am": return "Amazonas";
      case "ba": return "Bahia";
      case "ce": return "Ceará";
      case "df": return "Distrito Federal";
      case "es": return "Espírito Santo";
      case "go": return "Goiás";
      case "ma": return "Maranhão";
      case "mt": return "Mato Grosso";
      case "ms": return "Mato Grosso do Sul";
      case "mg": return "Minas Gerais";
      case "pa": return "Pará";
      case "pb": return "Paraíba";
      case "pr": return "Paraná";
      case "pe": return "Pernambuco";
      case "pi": return "Piauí";
      case "rj": return "Rio de Janeiro";
      case "rn": return "Rio Grande do Norte";
      case "rs": return "Rio Grande do Sul";
      case "ro": return "Rondônia";
      case "rr": return "Roraima";
      case "sc": return "Santa Catarina";
      case "sp": return "São Paulo";
      case "se": return "Sergipe";
      case "to": return "Tocantins";
      default: return "";
    }
  },      
  RetornaMes                : (mes) => {
    //verificar o número do mês e retornar o nome
    switch (mes) {
      case 1  : return "Janeiro";
      case 2  : return "Fevereiro";
      case 3  : return "Março";
      case 4  : return "Abril";
      case 5  : return "Maio";
      case 6  : return "Junho";
      case 7  : return "Julho";
      case 8  : return "Agosto";
      case 9  : return "Setembro";
      case 10 : return "Outubro";
      case 11 : return "Novembro";
      case 12 : return "Dezembro";
      default : return "Erro";
    }
  },      
  OrganizarDias             : (data, range) => {
    // Array de dias
    const days = [];
  
    // Loop para adicionar os dias
    for (let i = (range*-1); i <= (range); i++) {
      const newDate = new Date(data);
      newDate.setDate(data.getDate() + i);
      days.push(newDate);
    }
    //console.log("DiasArray: ", days)
    return days;
  },
  ReturnLabelURL            : (estad, city) => {
    const ct  = `${city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-')}`;
    const label = `${Services.RetornaEstados(estad).toLowerCase()}-${ct}`;
    return label;
  },    
  ReturnToStringDate        : (data) => {
    const dia = data.getDate() < 10 ? ("0" + data.getDate()) : data.getDate();
    const mes = (data.getMonth() + 1) < 10 ? ("0" + (data.getMonth()+1)) : (data.getMonth()+1);
    const ano = data.getFullYear();
    //console.log(`Data Service strinDate: ${dia}/${mes}/${ano}`)
    return `${dia}/${mes}/${ano}`;
  },    
  ReturnStringDay           : (data) => {
    // Verificar o dia da semana e retornar o dia em string
    switch (data.getDay()) {
      case 0 : return "DOM";
      case 1 : return "SEG";
      case 2 : return "TER";
      case 3 : return "QUA";
      case 4 : return "QUI";
      case 5 : return "SEX";
      case 6 : return "SÁB";
      default: return "Erro";
    }
  },      
  ReturnDay                 : (day) => {
    switch (day) {
      case 0 : return "Domingo";
      case 1 : return "Segunda";
      case 2 : return "Terça";
      case 3 : return "Quarta";
      case 4 : return "Quinta";
      case 5 : return "Sexta";
      case 6 : return "Sábado";
      default: return "Erro no Services.ReturnDay()";
    }
  },      
  ReturnDataMsg             : (date) => {
    return `${Services.ReturnDay(date.getDay())}, ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} às ${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
  },  
  ReturnHorarioString       : (pedido) => {
    let horarioInicial = pedido.agenda[0].horario.horarioInicial.split(":");
    let horarioFinal   = pedido.agenda[(pedido.agenda.length - 1)].horario.horarioFinal.split(":");  
    return `${horarioInicial[0]}:${horarioInicial[1]} às ${horarioFinal[0]}:${horarioFinal[1]}`;
  },
  RetornaDuracaoFixa        : (hrFix) => {
    const hrInit = hrFix.prePedidos[0].agendamentos[0].horario.horarioInicial.split(":");
    const hrEnd  = hrFix.prePedidos[hrFix.prePedidos.length - 1].agendamentos[hrFix.prePedidos[hrFix.prePedidos.length - 1].agendamentos.length - 1].horario.horarioFinal.split(":");
    return `${hrInit[0]}:${hrInit[1]} às ${hrEnd[0]}:${hrEnd[1]}`;
  },
  ReturnHorarioLabel        : (init, end) => {
    const dataI = new Date(init);
    const dataF = new Date(end);
    return `${dataI.getHours() >= 10 ? dataI.getHours() : "0"+dataI.getHours()}:${dataI.getMinutes() < 10 ? "0" + dataI.getMinutes() : dataI.getMinutes()} às ${dataF.getHours() >= 10 ? dataF.getHours() : "0"+dataF.getHours()}:${dataF.getMinutes() < 10 ? "0" + dataF.getMinutes() : dataF.getMinutes()}`;
  },
  ReturnDataStringCompleta : (pedido) => {
    const data = new Date(pedido.agenda[0].data);
    return `${Services.ReturnDay(data.getDay())}, ${data.getDate()} de ${Services.RetornaMes(data.getMonth() + 1)} de ${data.getFullYear()}`;
  },
  ReturnDataStringCompletaResumo : (data) => {
    return `${Services.ReturnDay(data.getDay())}, ${data.getDate()} de ${Services.RetornaMes(data.getMonth() + 1)} de ${data.getFullYear()}`;
  },
  RetornaForcaSenha         : (senha) => {
    if(senha.length){
      const { score } = zxcvbn(senha);
      // Verifica a força da senha
      switch (score) {
        case 0:
          return "Senha muito fraca";
        break;
        case 1:
          return "Senha fraca";
        break;
        case 2:
          return "Senha razoável";
        break;
        case 3:
          return "Senha forte";
        break;
        case 4:
          return "Senha muito forte";
        break;
        default:
          return "";
        break;
      } 
    } else return "";
  },  
  RemoveHorariosPassados    : (horarios, agora) => {
    const horariosFuturos = [];
    horarios.forEach((horario) => {
      const dataHorario = new Date(horario.data);
      if (dataHorario > agora) {
        horariosFuturos.push(horario);
      }
    });
    return horariosFuturos;  
  },  
  RetornFaltaHora           : (hor) => {
    function VerificaDiferença(mode, horas){
      if(mode){
        if(horas < 24){
          return {
            state : true, 
            label :`EM ${horas} HORA${horas == 1 ? "" : "S"}`,
          };
        } else {
          const dias = Math.floor(horas / 24);
          if(dias < 7) {
            return {
              state : false,
              label : `EM ${dias} DIA${dias == 1 ? "" : "S"}`
            };
          }
          else if(dias < 30) {
            const semanas = Math.floor(dias / 7);
            return {
              state : false,
              label : `EM ${semanas} SEMANA${semanas == 1 ? "" : "S"}`
            };
          } else if(dias < 365){
            const meses = Math.floor(dias / 30);
            return {
              state : false,
              label : `EM ${meses} ${meses === 1 ? "MÊS" : "MESES"}`
            };
          } else {
            const anos = Math.floor(dias / 365);
            if(anos == 1){
              return {
                state : false,
                label : `EM ${anos} ANO`
              };
            }
            else {
              return {
                state : false,
                label : `EM ${anos} ANOS`
              };
            }
          }
        }
      } else {
        if(horas < 23){
          return {
            state : false,
            label : `HÁ ${horas} HORA${horas > 1 ? "S" : ""}`
          };
        } else { 
          const dias = Math.floor(horas / 24);
          if(dias < 7) {
            return {
              state : false,
              label : `HÁ ${dias} DIA${dias == 1 ? "" : "S"}`
            };
          }
          else if(dias < 30) {
            const semanas = Math.floor(dias / 7);
            return {
              state : false,
              label : `HÁ ${semanas} SEMANA${semanas == 1 ? "" : "S"}`
            };
            
          } else if(dias < 365){
            const meses = Math.floor(dias / 30);
            return {
              state : false,
              label : `HÁ ${meses} ${meses === 1 ? "MÊS" : "MESES"}`
            };
          } else {
            const anos = Math.floor(dias / 365);
            return {
              state : false,
              label : `HÁ ${anos} ANO${anos == 1 ? "" : "S"}`
            };
          }
        }
      }
    }
    // Recebe um objeto Date e define quanto tempo em horas falta para o horário, ou se já passou
    // Retorna uma tag "Realizado".
    const agora   = new Date();
    const falta   = hor - agora;
    // Converte para minutos
    let minutos   = Math.floor(falta / 60000);
    let horas     = Math.floor(falta / 3600000);
    if (horas > 0) {
      if(minutos < 60){
        return {
          state : true,
          label : `EM ${minutos} MINUTOS`
        }
      } else {
        return VerificaDiferença(true, horas);
      }
    } else{
      horas = horas * (-1);
      return VerificaDiferença(false, horas);
    } 
    
  },
  ReturnHoraMin             : (hor) => {
    // Recebe um objeto Date e retorna a hora e minutos
    const horas   = hor.getHours()    < 10 ? "0" + hor.getHours()   : hor.getHours();
    const minutos = hor.getMinutes()  < 10 ? "0" + hor.getMinutes() : hor.getMinutes();
    return `${horas}:${minutos}`;
  },  
  ReturnValorPorcentagem    : (porcentagem, valor) => {
    return ((valor * porcentagem) / 100).toFixed(2);
  },
  OrganizaArrayCalendar     : (daySel) => {
    function VeryfyMes(day){
      switch (day.getMonth()) {
        case 0: return 31;
        case 1: return 28;
        case 2: return 31;
        case 3: return 30;
        case 4: return 31;
        case 5: return 30;
        case 6: return 31;
        case 7: return 31;
        case 8: return 30;
        case 9: return 31;
        case 10: return 30;
        case 11: return 31;
        default: return 0;
      }
    }
    const array = [];
    const mes = VeryfyMes(daySel);
    const ano = daySel.getFullYear();
    for (let i = 1; i <= mes; i++) array.push(new Date(ano, daySel.getMonth(), i));
    return array;
  },
  ReturnDayComplet          : (day) => {
    switch (day) {
      case 0 : return "Domingo";
      case 1 : return "Segunda-feira";
      case 2 : return "Terça-feira";
      case 3 : return "Quarta-feira";
      case 4 : return "Quinta-feira";
      case 5 : return "Sexta-feira";
      case 6 : return "Sábado";
      default: return "Erro no Services.ReturnDay()";
    }
  },
  RetornaRegraCancelamento : (regra) => {
    if (regra.minutosAntecedencia === 0) return <p>{`Cancelamento GRATUITO até o horário do agendamento.`}</p>;
    else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta === 0 && regra.permitirCancelamentoPosHorario === 1) {
      return <p>{`'Você pode cancelar até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>;
    } else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta > 0 && regra.permitirCancelamentoPosHorario === 1) {
      return(
        <>
          <p>{`Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>
          <p>{`Se você cancelar após o horário limite, será cobrada uma taxa de ${regra.porcentagemMulta}% do valor total do agendamento.`}</p>
          <p>{`Se o pagamento for um sinal, ele será a multa.`}</p>
        </>
      );
    } else if (regra.minutosAntecedencia !== 0 && regra.permitirCancelamentoPosHorario === 0) {
      return (
        <>
          <p>{`Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`}</p>
          <p>{`Após o horário limite NÃO será possível cancelar e você deverá entrar em contato com o Gestor pelo chat.`}</p>
        </>
      );
    } else return <p>{regra.permitirCancelamentoPosHorario}</p>
  },
  RetornaRegraCancelamentoSwal : (regra) => {
    if (regra.minutosAntecedencia === 0) return `Cancelamento GRATUITO até o horário do agendamento.`;
    else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta === 0 && regra.permitirCancelamentoPosHorario === 1) {
      return `Você pode cancelar até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário.`;
    } else if (regra.minutosAntecedencia !== 0 && regra.porcentagemMulta > 0 && regra.permitirCancelamentoPosHorario === 1) {
      return `Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário. Se você cancelar após o horário limite, será cobrada uma taxa de ${regra.porcentagemMulta}% do valor total do agendamento. Se o pagamento for um sinal, ele será a multa.`;
    } else if (regra.minutosAntecedencia !== 0 && regra.permitirCancelamentoPosHorario === 0) {
      return `Você pode cancelar GRATUITAMENTE até ${retornaMinutagem(regra.minutosAntecedencia)} antes do horário. Após o horário limite NÃO será possível cancelar e você deverá entrar em contato com o Gestor pelo chat.`;
    } else return regra.permitirCancelamentoPosHorario;
  },
  RetornaLabelUrl           : (cidade, username) => {
    const sigla = username.split("-")[0];
    //console.log("sigla = ", sigla);
    const cidadeFormat = cidade.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-').toLowerCase();
    //console.log("cidadeFormat = ", cidadeFormat);
    return `${sigla}-${cidadeFormat}`;
  },
  ModuloChatAbertaNaoEnviado : {
    /*
      Salva o chat aberto , porém sem mensagens, após a primeira mensagem enviada,
      o chat é removido do localStorage.
      A tabela salva os keys do chat, indicado pelo seu idchat!
      tabela = [idchat, ... ]
    */
    SalveChat     : (chat, idchat)  => LocalStorage.set(idchat, chat),
    ReturnChat    : (idchat)        => LocalStorage.get(idchat),
    DeleteChat    : (idchat)        => LocalStorage.remove(idchat),
    SalveTabela   : (tabela)        => LocalStorage.set('tabela-chat', tabela),
    ReturnTabela  : ()              => LocalStorage.get('tabela-chat'),
    DeleteTabela  : ()              => LocalStorage.remove('tabela-chat'), // caso tabela sem chats abertos = null!!!
  }
} ; // Fim de Services
export default Services;

function retornaMinutagem(minutos) {
  if (minutos >= 60 && minutos < 1440) {
    let minutagem = minutos / 60 + ''
    let retorno = minutagem.split('.')          
    return retorno[0] + ' hora(s)';
  } else if (minutos < 60) {
      return minutos + ' minuto(s)';
  } else {
    let minutagem = minutos / 1440 + ''
    let retorno = minutagem.split('.')
    return retorno[0] + ' dia(s)'
  }
}