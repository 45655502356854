import React                  from "react";
import md5                    from "md5";

import { LocalStorage }       from "../services";
import Auth from "../servicesAPI/Auth";

export const AuthContext = React.createContext({
  date        : LocalStorage.get("date")    || {gestor: {}, empresa: {}, usuarios: []},
  token       : LocalStorage.get("token")   || null,
  isAuth      : LocalStorage.get("isAuth")  || false,
  modalOpen   : false,
  loading     : false,
  setLoading  : () => {},
  login       : async (email, senha) => {console.log("Aqui!!!")},
  logout      : async (token) => {},
  isLogged    : async (token) => {},
  register    : async (buffer) => {},
  handleDate  : (data) => {},
  
});

export default function AuthContextProvider({children}) {

  const [date, setDate]       = React.useState(LocalStorage.get("date") || {gestor: {}, empresa: {}, usuarios: []});
  const [token, setToken]     = React.useState(LocalStorage.get("token") || null);
  const [isAuth, setIsAuth]   = React.useState(LocalStorage.get("isAuth") || false);
  const [modalOpen, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  // Seta o date no localStorage e no state
  function setDateContext(date){
    setDate(date);
    LocalStorage.set('date', date);
  }
  const handleDate = (date) => setDateContext(date);
  // Seta o token no localStorage e no state
  function setTokenContext(val){
    setToken(val);
    LocalStorage.set('token', val);
  }

  // Seta o isAuth no localStorage e no state
  function setIsAuthContext(val){
    setIsAuth(val);
    LocalStorage.set('isAuth', val);
  }

  // Seta o modalOpen no state
  function setModalOpen(val){
    setIsAuth(val);
    LocalStorage.set('modalOpen', val);
  }

  // Seta o loading no state
  function setLoadingContext(val){
    setLoading(val);
  }

  // Função para fazer login
  async function login(email, password){
    console.log("Email: ", email, "Senha: ", password);
    const resp = await Auth.login(email, password);
    console.log("Resposta Form: ", resp);
    if(resp.status){
      return {status: true, req: resp};  
    } else {
      return {status: false, message: "Erro ao tentar se conectar ao servidor. Tente novamente mais tarde.", req: resp}
    }
  }

  // Função para fazer logout
  async function logout(){
    
  }

  // Função para verificar se o usuário está logado
  async function isLogged(){

  }

  // Função para registrar um novo usuário
  async function register(){

  }

  return (
    <AuthContext.Provider 
      value={{
        date, token, isAuth, modalOpen, loading,
        setLoading : setLoadingContext,
        login, logout, isLogged, register, handleDate
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
